@import 'node_modules/react-modal-video/scss/modal-video.scss';
.sub-title {
    font-size: 15px;
    color: #000 !important;
}

.full_wrap {
    width: 100%;
    display: inline-block;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
}
.half_wrap {
    width: 50%;
    display: inline-block;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.out-box-new-content-wrap {
    background-color: #0F1C1E;
    padding: 30px 20px;
    border-radius: 10px;
}
.head-tile-create {
    text-align: center;
    .step-title {
        text-transform: uppercase;
        font-size: 15px;
        color: #6C7273;
    }
    .main-title {
        color: white;
        font-family: 'avenir-bold';
        margin: 0px;
    }
}
:root {
    --s-gutter: 2.5rem;
    --c-background: rgb(0, 0, 0);
    --c-accent: #FAAA2F;
}
///////
// STEPPER CSS
///////
.stepper {
    $root: &;
    --s-stepper-bullet: 2rem;
    --s-stepper-bullet-half: calc(var(--s-stepper-bullet) / 2);
    --step-transition: background .5s, color .5s;
    // all colors are define for the active state
    --step-content: "\2713";
    --step-color: hsl(0, 0%, 70%);
    --step-bar-bg: var(--c-accent);
    --step-bullet-bg: var(--step-bar-bg);
    --step-bullet-color: white;
    counter-reset: current-step;
    // Use grid here
    // • we can support a multiline stepper by increasing the minmax min level
    // • grid is more strict at respecting column than flexbox: a very small width, the contents will overlap
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
    position: relative;
    z-index: 1;
    &[data-debug] {
        counter-reset: steps;
        &::after {
            content: "number of steps: " counter(steps);
            display: block;
            position: absolute;
            bottom: -1.5rem;
            width: 100%;
            text-align: center;
            color: hsl(40, 50%, 50%);
        }
        input {
            --s-separation: 1.5rem;
            position: absolute;
            display: initial;
            top: -2rem;
            @for $i from 1 through 10 {
                &:nth-of-type(#{$i}) {
                    left: calc(var(--s-separation) * #{$i} + .75rem);
                }
            }
        }
    }
    // don't know
    &__input {
        counter-increment: steps;
        display: none;
    }
    &__step {
        counter-increment: current-step;
        // everything beyond the current step is deactivated
        #{$root}__input:checked~& {
            --step-color: hsl(0, 0%, 30%);
            --step-bar-bg: hsl(0, 0%, 40%);
            --step-bullet-bg: var(--step-bar-bg);
            --step-bullet-color: hsl(0, 0%, 20%);
            --step-content: counter(current-step);
            // we can also use “display: none”
            // • but we take an opacity approach to keep the height of the stepper coherent with the bigger step
            #{$root}__content {
                opacity: 0;
                pointer-events: none;
                user-select: none;
            }
        }
        // This is the current step
        // • restore active colors & content
        #{$root}__input:checked+& {
            --step-bullet-bg: #FAAA2F;
            --step-bullet-color: white;
            --step-color: white;
            #{$root}__button {
                &::before {
                    box-shadow: 0 0 0 2px hsla(0, 0%, 70%, 1);
                }
            }
            #{$root}__content {
                opacity: 1;
                pointer-events: auto;
                user-select: auto;
            }
        }
    }
    &__content {
        color: white;
        text-align: center;
        font-weight: 700;
        color: var(--step-color);
        transition: opacity .5s .05s;
        padding: .5rem;
        font-size: 15px;
        &::selection {
            color: black;
            background: var(--step-bullet-color);
        }
    }
    &__button {
        position: relative;
        text-align: center;
        color: var(--step-color);
        display: block;
        // bullet
        &::before {
            content: var(--step-content);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto var(--s-stepper-bullet-half);
            height: var(--s-stepper-bullet);
            width: var(--s-stepper-bullet);
            border-radius: var(--s-stepper-bullet);
            transition: var(--step-transition);
            background: var(--step-bullet-bg);
            color: black;
        }
        // progress bar
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: calc(var(--s-stepper-bullet-half) / 2);
            background: var(--step-bar-bg);
            transition: var(--step-transition);
            top: var(--s-stepper-bullet-half);
            left: 50%;
            transform: translate(0, -50%);
            z-index: -1;
            // connecting lines are always steps.length - 1
            #{$root}__step:last-child & {
                display: none;
            }
        }
    }
    // we can also use CSS flexbox.
    // • but when not enough space
    //   columns won't be  respected anymore
    &--flexbox {
        display: flex;
        #{$root}__step {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 0;
        }
    }
}
///////
// DEMO UTILS
///////
.container {
    &,
    &__item {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    &__item {
        padding: var(--s-gutter) calc(50% - var(--s-width) / 2);
        h2 {
            padding: calc(var(--s-gutter) / 2) var(--s-gutter) var(--s-gutter);
            margin: 0;
            text-transform: uppercase;
            font-weight: 100;
            color: hsl(213, 15%, 60%);
            font-size: 1.4rem;
        }
    }
}
.steps-out-wrap {
    padding-top: 40px;
}
.list-content-out-wrap {
    padding: 10px 10px;
    position: relative;
    .list-content-ul {
        padding: 0%;
        margin: 0%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 25px;
    }
    .list-content-li {
        padding: 0%;
        margin: 0%;
        display: inline-block;
        cursor: pointer;
        width: 100%;
        position: relative;
        .inner-li-wrap {
            border: 1px solid #273334;
            border-radius: 10px;
            color: white;
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 0% 2.5%;
            padding: 10px;
            img {
                width: 40px;
                height: 40px;
                border-radius: 20px;
            }
            img.selected-detereminant{
                width: 20px !important;
                height: 20px !important;
                border-radius: 20px;
            }
            p {
                font-size: 17px;
                padding-bottom: 0px !important;
            }
            .ab-tick-btn {
                position: absolute;
                right: 14px;
                // width: 20px;
                display: none;
            }
            .content-type-description{
                line-height: 18px;
                font-size: 14px;
                color: #6C7273;
            }
        }
        .text-wrap-for-height{
            line-height: 20px !important;
        }
        .min-h-uploadUI{
            height: 16vh !important;
        }
    }
}
.inner-li-wrap.check-btn-dot {
    background-color: #FAAA2F;
    p {
        color: #0F1C1E;
        font-weight: 800;
    }
    .ab-tick-btn {
        display: block !important;
    }
}
.min-h-vh {
    min-height: 45vh;
}
.bottom-btn-side-wrap {
    padding: 0px 10px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}
.step-btn-wrap {
    background-color: #727A7B;
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    &:hover {
        background-color: white;
        color: black;
        img {
            filter: invert(1);
        }
    }
}
.ab-drop-search-section {
    background-color: #192527;
    position: absolute;
    width: 100%;
    z-index: 1000;
    display: none;
    top: 60px;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 1px 4px 1px #252323;
}
.search-drop-wrap {
    input {
        background: #222E30;
        padding-left: 40px;
        border-radius: 10px;
    }
    .ab-search-icon-inner-wrap {
        position: absolute;
        top: 17px;
        left: 17px;
        z-index: 100;
        width: 20px;
    }
}
.inner-li-wrap.check-btn-dot+.ab-drop-search-section {
    display: block;
}
.list-search-out-wrap::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.list-search-out-wrap::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #273334;
    border-radius: 10px;
}
.list-search-out-wrap {
    margin-top: 10px;
    max-height: 200px;
    padding: 0px 5px;
    overflow-y: auto;
    .select-search-list {
        border-radius: 10px;
        border: 1px solid #273334;
        text-align: center;
        text-decoration: none;
        color: white;
        position: relative;
        display: inline-block;
        padding: 7px 10px;
        margin-right: 15px;
        margin-bottom: 10px;
        cursor: pointer;
    }
    .select-search-list.active-btn {
        background: #FAAA2F;
        color: black;
    }
}

.content-fields{
    .content-label{
        color: hsl(213, 15%, 60%);
    }
}

.image-border-ring {
    padding: 15px;
    border-radius: 40px;
    border: 1px solid #2F3A3C;
}
.upload-active {
    border: 3px solid #FAAA2F !important;
    border-radius: 10px !important;
    .image-border-ring{
        background-color: #2F3A3C;
    }
}
.select-tag-header{
     font-size: 12px;
     color: #fff;
     letter-spacing: 0.1em;
     margin: 20px 0px;
}
.list-wrap-drop{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.3% 2.5%;
}
.cus-in-text-wrap{
    width: 100%;
    background-color: #192527;
    border: 1px solid #1d292b;
    border-radius: 10px;
    color: white;
    &:hover{
        background-color: #0F1C1E;
    }
    &:focus-visible{
        border: 1px solid #FAAA2F !important;
    }
}
.tick-curve-ab{
    position: absolute;
    right: 0px;
    top: -7px;
    display: none;
    img{
        border-radius: 0px !important;
        width: 20px !important;
        height: 20px !important;
    }
}
.upload-active {
    .tick-curve-ab{
        display: block;
    }  
}