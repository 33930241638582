.analytics-container {
  background-color: var(--bg-color);
  display: grid;
  grid-template-areas:
    'analytics analytics analytics topcontent topcontent topcontent'
    'contents contents contents  topcontent topcontent topcontent';
  grid-gap: 20px;
  margin-top: 20px;
  border-radius: 5px;
}

@media screen and (max-width: 769px) {
  .analytics-container {
    background-color: red;
    display: grid;
    grid-template-areas:
      'analytics '
      'contents'
      'topcontent';
    grid-gap: 10px;
  }
}

.analytics-1 {
  grid-area: analytics;
  border-radius: 5px;
  padding-left: 20px;
  max-width: 735px;
  min-width: 340px;
}

@media screen and (max-width: 769px) {
  .analytics-1 {
    min-width: 340px;
  }
}

.analytics-2 {
  grid-area: contents;
  border-radius: 5px;
  max-width: 735px;
}

@media screen and (max-width: 769px) {
  .analytics-2 {
    min-width: 340px;
  }
}

.analytics-3 {
  grid-area: topcontent;
  border-radius: 5px;

  // min-width: 340px;

  hr {
    &:nth-last-child(1) {
      display: none;
    }
  }

}

.inner-scroll-wrap {
  overflow-y: scroll;
  max-height: 600px;
}

.analytics-container {
  .slick-list {
    border: 1px solid #263133;
    border-radius: 10px;
  }

  .slick-prev {

    &::before {
      color: #FAAA2F;
      opacity: 1;
    }

    left: -10px;
    z-index: 100;
  }

  .slick-next {
    right: -10px;
    z-index: 100;

    &::before {
      color: #FAAA2F;
      opacity: 1;
    }

  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    color: #727A7B;
    opacity: 1;
  }
}

@media screen and (max-width: 769px) {
  .analytics-3 {
    min-width: 340px;
  }
}

.analytics-container>div {
  background-color: #0e1b1d;
  border: 1px solid #222e30;
  padding-top: 10px;
}

.inner-scroll-wrap::-webkit-scrollbar {
  width: 5px;
}

.inner-scroll-wrap::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
}

.inner-scroll-wrap::-webkit-scrollbar-thumb {
  background-color: rgb(2, 69, 54);
  border-radius: 5px;
}

.content h6 {
  font-size: 20px;
}

.total-content {
  display: flex;
  gap: 8px;
  align-items: center;
}

.total-content h6 {
  font-size: 12px;
  text-transform: uppercase;
  margin: 0px;
  margin-right: 5px;
}

.color p {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(47, 243, 220);
  margin-right: 5px;
  display: inline-block;
  padding: 0;
  margin: 0 5px 0 0;
}

.color-dark p {
  background-color: #1d3437;
}

.top-contents {
  display: flex;
  justify-content: space-between;
  padding: 5px 11px;
  margin-bottom: 5px;
  margin-top: 0px;
  padding-top: 0px;
  align-items: center;
}

.top-text {
  padding-top: 6px;
}

.top-text h1 {

  font-size: 18px;
  margin-bottom: 0px;
}

.rating-button {
  background-color: #323d3f;
  padding: 5px 3px;
  border-radius: 7px;
}

.rating-button-1 {
  border: none;
  outline: 0;
  margin-left: 2px;
  padding: 8px 12px 8px 12px;
  border-radius: 7px;
  font-size: 16px;
  font-weight: 400;
  background-color: transparent;
  color: white;
  transition: all 0.3s ease-in-out;  

}

.rating-button-1:hover {
  background-color: #fff;
  color: black;
}

.rating-button-2 {
  background-color: #fff;
  color: black;
}

.full-mode-chart {
  position: absolute;
  right: 20px;
  top: 12px;
  z-index: 100;
  cursor: pointer;
}

.chart-title-container {
  display: flex;
  justify-content: space-between;
  padding: 17px 20px;
  border-bottom: 1.2px solid #e7e8e8;

  h3 {
    margin-bottom: 0px;
    font-size: 23px;
  }
}

.close-icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.list-chart-ul::-webkit-scrollbar {

  width: 4px;
  height: 4px;

}

.list-chart-ul::-webkit-scrollbar-thumb {

  cursor: pointer;
  background: #82898A;
  border-radius: 10px;

}

.list-chart-ul {
  height: 488px;
  overflow: auto;
  padding: 0px;
  margin: 0px;
  display: inline-block;
  float: left;
  position: relative;
  width: 100%;
  border-right: 1.2px solid #e7e8e8;

  li {
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    float: left;
    position: relative;
    padding: 10px 20px;
    border-bottom: 1.2px solid #e7e8e8;
    width: 100%;

    &:nth-last-child(1) {
      border-bottom: 1px solid #e7e8e800;
    }

    .left-side {
      p {
        font-size: 17px;
        color: black;
      }
    }

    .right-side {
      p {
        display: flex;
        align-items: center;
        gap: 2px;
        color: #727a7b;
        font-size: 14px;
      }

      svg {
        width: 15px;
      }
    }
  }
}

.chart-slide-wrap {
  
  display: flex;
  flex-direction: column;
  justify-content: center;

}
#SvgjsText1286{
  display: none !important;
}


