.MuiFormControl-root {
  width: 100%;
  margin: 10px 0px 10px 0px !important;
  border-radius: 10px;
  border: 1px solid #1e777e;
}

/* Apply border color globally to all MUI Select components */
.MuiOutlinedInput-root {
  outline: none !important;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #1e777e !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1e777e !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1e777e !important;
  box-shadow: none !important;
}
