@import "../../../css/variables.scss";

.user-container {
  background-color: #0f1c1e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 33px;
  border: 1px solid #273334;
  border-radius: 10px;
  color: white;
}

.user-menu-item ul {
  display: flex;
  gap: 1rem;
  justify-items: flex-end;
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.user-menu-item ul li {
  color: white;
  display: flex;
  gap: 0.5rem;
  // text-transform: uppercase;
  cursor: pointer;
  align-items: center;
  font-size: 13px;
}

.user-menu-item ul li img {
  margin-right: 5px;
}

.active {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: green;
  font-family: "avenir-bold";
}

.inactive {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
}

.dialog-container {
  padding: 0px;
  width: 390px;
}

.dialog-container h5 {
  border-bottom: 1px solid rgb(247, 232, 232);
  padding: 17px 20px;
  font-size: 20px;
  font-weight: 600;
  padding-top: 22px;
  margin-bottom: 20px;
}

.dialog-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 4px;
}

.dialog-item label {
  letter-spacing: 1px;
  // font-weight: lighter;
}

.model-label-text {
  font-family: "avenir-semi";
  font-size: 10px;
  color: #727a7b;
  font-weight: 900;
  letter-spacing: 0.2em !important;
}

.dialog-item input {
  padding: 20px 10px;
  background-color: rgba(114, 122, 123, 0.1019607843);
  border: 1px solid rgba(114, 122, 123, 0.1019607843);
  font-family: "avenir-bold";
  font-size: 15px;
  line-height: 1em;
  padding-bottom: 14px;
}

.input-dialog {
  background-color: white;
  color: #041214;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 8px;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0px 4px 4px 0px #b7c5c7;
}

.input-dialog:focus {
  border: 1px solid #faaa2f;
  box-shadow: 0px 0px 0px 0px #727a7b;
}

.dialog-item .inner-dialog-item {
  display: flex;
  flex-direction: column;
}

.dialog-item .inner-dialog-item:nth-child(2) input {
  margin-bottom: 0px;
}

.send-message-btn {
  background: #257076 !important;
  color: white !important;
  border: 0px;
  padding: 10px;
  border-radius: 9px;
  margin-top: 30px;
  height: 54px;
  font-family: "avenir-bold";
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
}

.ab-close-btn {
  position: absolute;
  right: 20px;
  top: 19px;
  width: 20px;
  cursor: pointer;
}

li.active-tabe {
  color: #faaa2f !important;
}

li.disActive-tabe {
  color: white !important;
}
.laberText {
  font-family: "avenir-bold";
  font-size: 13px;
}
.user-menu-item ul li p {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.dot-active {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #0fe10f;
  display: block;
  margin-bottom: 4px;
}

.dot-inactive {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  display: block;
  margin-bottom: 4px;
}

.model-title {
  font-family: "avenir-bold";
  font-size: 20px;
}
.title-text {
  text-transform: capitalize;
}

.user-body {
  height: calc(100vh - 400px);
}

.position-relative {
  height: 100vh;
  // overflow: hidden;
}
