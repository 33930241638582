@import '../../css/variables.scss';

body {
    margin: 0px;
}

#root {
    margin: 0;
}

.login-container {
    height: 100%;
    width: 100%;
    background-color: #051315;
}

.gridContainer {
    width: 65%;
    padding-left: 0.75%;
    padding-right: 0.75%;
    margin: auto;
    clear: none;
    float: none;
    margin-left: auto;
}

.text-center {
    text-align: center;
}

.out-box-wrap {
    height: 100vh;
    display: flex;
    align-items: center;
}

.company__info {
    

    width: 100%;
    height: 100%;
    position: relative;
}

.fa-android {
    font-size: 3em;
}

@media screen and (max-width: 640px) {


    .company__info {
        display: none;
    }

    .login_form {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
}

.login_form {
    background-color: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 1px solid #705e5e;
    border-left: 0px;
    width: 50%;
    background-color: #142123;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 50px;
}

form {
    padding: 0 2em;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.cus-label {
    color: white;
    cursor: pointer;
    
}

.title {
    color: white;
    font-family: 'avenir-bold';
}

.subTitle {
    color: #727A7B;

}

.white {
    color: #fff;
}

.rememberForgotPasswordContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ant-checkbox-inner::after {
    width: 13.714286px;
    height: 23.142857px;
}

.bottom-section {
    justify-content: flex-end;
}