.user-details-container {
  background-color: var(--bg-color);
  // height: calc(100vh - 52px);

  // padding: 35px;
  .userdetail-item-header {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .user-details-box {
    background: transparent;
    border: 1px solid #263234;
    border-radius: 12px;
  }

  .userdetail-item-header h1 {
    font-size: 20px;
    font-weight: 700;
  }

  .user-card {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    background-color: white;

    .user-card-inner-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;


      .user-card-contact {
        margin-left: 40px;
        background-color: rgb(235, 230, 230);
        padding: 4px;
        border-radius: 4px;
      }
    }
  }

}

.user-model-details-container {
  background-color: var(--bg-color);
  // height: calc(100vh - 52px);

  // padding: 35px;
  .userdetail-item-header {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .user-details-box {
    background: transparent;
    border: 1px solid #263234;
    border-radius: 12px;
  }

  .userdetail-item-header h1 {
    font-size: 20px;
    font-weight: 700;
  }

  .user-card {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    background-color: white;

    .user-card-inner-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;


      .user-card-contact {
        margin-left: 40px;
        background-color: rgb(235, 230, 230);
        padding: 4px;
        border-radius: 4px;
      }
    }
  }

}

.inner-user-ul-list {
  display: flex;
  width: 100%;
  position: relative;
  padding: 0px;
  margin: 0px;
  padding: 14px 10px;
  padding-bottom: 4px;

  li {
    position: relative;
    list-style: none;
    display: flex;
    margin-right: 20px;
    cursor: pointer;

    >* {
      padding-right: 10px !important;

      &:nth-last-child(1) {
        padding-right: 0px !important;
      }
    }
  }
}

hr {
  border: 1px solid #fff;
}

.MuiCardContent-root:last-child {
  border-bottom: 0px;
}

.active-tabe .cus-svg path {
  stroke: #FAAA2F;
}

.social-icon-user-details {
  align-items: center
}

.sub-inner-child-p {
  color: #687172;
  letter-spacing: 0.02em;
}
