.topcontentcard-container {
  position: relative;
  color: #000;
  display: flex;
}

.card {
  flex-direction: row;
  height: fit-content;
  max-height: 140px;
  background-color: rgb(0 19 15 / 0%);
  border-radius: 10px 0px 0px 10px;
  border: 0;
  cursor: pointer;
}

.card img {
  width: 30%;
  object-fit: cover;
  object-position: 100% 50%;
}

.card-body {
  padding: 0;
}

.card-body h5 {
  font-size: 12px;
  color: gray;
  padding-left: 6px;
}

.card-body h6 {
  font-size: 12px;
  color: #fff;
  padding-left: 6px;
}

.card-text-1 {
  color: #fff;
  display: flex;
  justify-content: center;
  margin-left: 5px;
  align-items: center;
}

.card-text-1 img {
  width: 18px;
  margin-right: 5px;
}

.card-img-top {
  border-radius: 10px;
}

.card-text-2 {
  color: #fff;
  background-color: rgb(64, 64, 64);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 5px;
  margin: 0px;
  padding: 5px 10px 4px 8px;
  font-size: 12px;
}

.card-text-2 img {
  width: 15px;
  margin-right: 5px;
}

.play-button {

  position: relative;
  display: contents;

  img {
    width: 30px;
    height: 30px;
  }
}

.playBtn {
  position: absolute;

  left: 13%;
  top: 38%;
  z-index: 99;
}

.yoga-image {
  max-width: 100%;
  border-radius: 8px;
}