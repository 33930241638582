@import 'node_modules/react-modal-video/scss/modal-video.scss';
.sub-title {
    font-size: 15px;
    color: #b6babb;
}

.contentd-ul-list {
    &:nth-child(4) {
        .contentd-list {
            border-right: 0px;
        }
    }

    .contentd-list {
        display: flex;

        .title-views {
            margin-left: 10px;
            color: #000;

            P {

                display: flex;
                flex-direction: column;
                color: #000;
                font-size: 11px;
                text-transform: uppercase;
                letter-spacing: 0.1em;

                span {
                    font-size: 16px;
                    color: #fff;
                }
            }
        }
    }


}

.contents-detals-wrap {
    .secendary {
        color: #fff;

        p {
            color: #727A7B;
        }
    }
}

.border-right {
    border-right: 1px solid #ffffff52;
}

.description-left-side {
    .inn-des-left-wrap {
        padding: 20px 20px;
        

        p {
            color: #929899;
            font-size: 15px;
            span {
                color: #faaa2f;
                cursor: pointer;
            }
        }
    }

}
.head-title-cus {
    color: #000;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 00.1em;
}
.link-copy-board{
    .MuiFormControl-root{
        border: 1px dashed !important;
    }
}
.ab-card-play-btn{
    position: absolute;
    left: 33%;
    top: 48%;
    width: 100%;
    height: 100%;
    z-index: 99;
    a{
        background-color: #00D8FF;
        color: black;
        text-decoration: none;
        padding: 10px 22px;
        margin: 0px;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        border-radius: 20px;
        svg{
            width: 15px;
        }
    }
}
.graph-right-side{
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.ab-card-play-btn{
    position: absolute;
    left: 33%;
    top: 48%;
    width: 100%;
    height: 100%;
    z-index: 99;
    a{
        background-color: #00D8FF;
        color: black;
        text-decoration: none;
        padding: 10px 22px;
        margin: 0px;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        border-radius: 20px;
        svg{
            width: 15px;
        }
    }
}
li.MuiBreadcrumbs-li{
    .MuiTypography-root{
        font-size: 16px !important;
    }  
}
.border-right{
    .MuiFormControl-root{
        background: #fff !important;
        border: 2px dashed #faaa2f !important;
    }
}

.graph-right-side .apexcharts-canvas .apexcharts-legend span.apexcharts-legend-text {
    color: #000 !important;
}


.graph-right-side .apexcharts-canvas .apexcharts-datalabels-group text {
    color: #000;
    fill: #000;
}
h4.common-header.avenir-bold.white {
    color: #000 !important;
}