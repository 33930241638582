.dashboard-container {
  background-color: var(--bg-color);
  width: 100%;
  padding: 10px 0px;
  color: white;
}

.header-container {
  display: flex;
  justify-content: space-between;
  
  align-items: center;
}

.left-item h1 {
  font-size: 25px;
}

.left-item p {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: gray;
  text-transform: capitalize;
}

.right-items {
  background-color: #293537;
  padding: 6px;
  border-radius: 10px;

}

.buttons {
  border: none;
  padding: 8px 12px 8px 12px;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
  
}

.buttons-2 {
  background-color: white;
  color: #000;
  font-weight: 600;
}

.buttons:hover {
  background-color: white;
  color: #000;
  
}

.chart-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.chart-item {
  width: 48%;
  background-color: #0e1b1d;
  margin-right: 10px;
  border-radius: 5px;
  padding: 10px 21px;
  border: 1px solid #222e30;
  position: relative;
}

.inner-chart-item {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.per-green {
  color: #40CE79;
}

.right-image {
  width: 50%;
  background-color: rgb(28, 84, 71);
  border-radius: 5px;
  position: relative;
}

.chart-value {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart-1 {
  background-color: var(--bg-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 30px;
  background-color: #041214;
}

.line-np {
  width: 38px;
  background: #0BD7EE;
  height: 2px;
  margin-top: 6px;
  margin-bottom: 6px;

  &.two {
    background: #FAAA2F;
  }

  &.three {
    background: #D33336;
  }
}

.chart-1 h1 {
  font-size: 18px;
  padding: 6px;
  margin: 0px;
  padding-bottom: 0px;
  font-family: 'avenir-bold';
}

.chart-1 span {
  font-size: 10px;
  color: gray;
  letter-spacing: 0.21em;
}

.right-image {
  background: transparent;

  img {
    width: 100%;
    border-radius: 10px;
    height: 330px;
    // height: 100%;
    object-position: 10% 100%;
  }
}

.review-item {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  color: white;
  padding: 15px;
}

.review-btn-item {
  position: absolute;
  left: 18px;
  right: 20px;
  bottom: 0px;
  display: flex;
  justify-content: space-between;

}

.newnew {
  padding: 40px;
}

.cus-svg-dash {
  svg {
    height: 185px;
  }

  circle {

    stroke-linecap: butt;
    stroke-width: 2;
    stroke-dasharray: 12;
    stroke: #565f61;
    transform: translateX(13.8px) translateY(15px) scaleX(0.9056) scaleY(0.923);
    stroke-linecap: round;
  }
}