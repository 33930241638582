.user-man-table-menu{
    background-color: red;
    width: 100%;
    display: flex;
    justify-content: space-between;
   
  }
 p{
    margin-bottom: 0px !important;
    font-size: 12px;
  }

  .user-table-like{
    background-color: rgb(38, 40, 39);
    padding: 5px 8px;
    border-radius: 4px;
    margin-right: 5px;
  }
 .user-active{
    display: flex;
    align-items: center;
    margin-top: 5px;
    
    .user-active-on{
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-right: 5px;
        background-color: #0fe10f;
      }
 } 

 .user-inactive{
  display: flex;
    align-items: center;
    margin-top: 5px;
    
    .user-active-on{
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-right: 5px;
        background-color: red;
      }
 }
 
.headerBorder th{
  border-bottom: 1px solid #273334 !important;
  color: #727A7B;
}

.overEffect{
  cursor: pointer;
}
.nameText{
  font-family: "avenir-bold";
    font-size: 17px;
    width: 101px;
    display: inline-block;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.nameTextDeterminats{
  font-family: "avenir-bold";
    font-size: 17px;
    width: 135px;
    display: inline-block;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.qualification{
  font-family: 'avenir-bold';
  font-size: 15px;
}
.expirence{
  font-family: 'avenir-semi';
  font-size: 15px;
  color: #727A7B;
}
.totalContent{
  font-family: 'avenir-bold';
  font-size: 15px;
}
.statushText{
  font-family: 'avenir-semi';
}
.table-row:hover{
  background-color: #192426;
}