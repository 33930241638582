@import '../../css/variables.scss';

.bottom-section{
    justify-content: flex-end;
}
.login_form {
    // border-top-left-radius: 20px;
    // border-bottom-left-radius: 20px;
}
.title {
    color: white;
    font-family: 'avenir-bold';
}