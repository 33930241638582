@font-face {
  font-family: "avenir-bold";
  src: url("../assets/fonts/avenir-bold.otf");
}
@font-face {
  font-family: "avenir-reg";
  src: url("../assets/fonts/avenir-reg.otf");
}
@font-face {
  font-family: "avenir-semi";
  src: url("../assets/fonts/avenir-semi.otf");
}
.avenir-bold {
  font-family: "avenir-bold";
}
.avenir-semi {
  font-family: "avenir-semi";
}
$global-background: #041214;
$white: white;
$btnPrimaryColor: "#0BD7EE";
$darkBackgroundColor: "#0f1c1e";

.op-5 {
  opacity: 0.5;
}
.pr-40 {
  padding-right: 40px;
}
.pl-15 {
  padding-left: 15px;
}
.h-64 {
  height: 64px !important;
}
.p-5 {
  padding: 5px !important;
}
.editBtnProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px !important;
  background-color: #1e777e;
  color: white;
  border: 0px;
  font-size: 18px;
  border-radius: 10px;
  text-transform: uppercase;

  // img {
  //   margin-right: 12px;
  //   width: 16px;
  //   margin-top: -2px;
  // }
}
.bg-dark {
  background-color: #0e1b1d !important;
}
.br-grey {
  border: 1px solid #263133;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}
.h-50 {
  height: 50px !important;
}
.css-821wyw-MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
}
.btn {
  font-family: "avenir-bold" !important;
}
.w-100 {
  width: 100%;
}
.f-12 {
  font-size: 12px;
}
.chart-title {
  font-size: 14px;
}
.fs-30 {
  font-size: 29px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
