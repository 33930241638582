@import "./css/variables.scss";

// @import url('https://fonts.googleapis.com/css2?family=Mirza&family=Roboto&display=swap');

body {
  font-family: "avenir-semi";
  background-color: #fff;
}

.ql-editor {
  font-family: "Roboto", sans-serif; /* default font */
  color: #000;
}

.ql-font-mirza {
  font-family: "Mirza", sans-serif !important; /* ensure it applies correctly */
}

.ql-font-roboto {
  font-family: "Roboto", sans-serif !important; /* ensure it applies correctly */
}

//////////////////////////////

.col-10.position-relative {
  background-color: #fff;
  height: unset !important;
}

.topbar-container,
input {
  background-color: #fff;
}

.menu-item-container {
  background-color: #fff;
}

h5.common-title.avenir-bold {
  color: #000;
}

p.op-5.mb-0.h6 {
  color: #000;
}

.sidebar-container {
  background-color: #fff;
}

.iconName {
  color: #000;
}

.overview-menu li .iconName img,
ul.general-menu li .iconName img {
  filter: contrast(0);
}

.side-image-container h1 {
  color: #000;
}

.chart-item {
  background-color: #e6fafc;
  color: #000;
  border: 1px solid #c5edf1;
}

text#SvgjsText4679 {
  fill: #000;
}

.chart-1 h1 {
  color: #fff;
}

.analytics-container > div {
  color: #000;
  background-color: #e6fafc;
  border: 1px solid #c5edf1;
}

.chart-graph.cus-svg-dash text {
  color: #000;
  fill: #000;
}

.chart-graph.cus-svg-dash text {
  color: #000;
  fill: #000;
}

.apexcharts-legend.apexcharts-align-undefined.apx-legend-position-top
  span.apexcharts-legend-text {
  color: #000 !important;
}

.analytics-container text {
  fill: #000;
}

.card-body h6 {
  color: #000;
}

.card-body p span {
  color: #000;
}

.analytics-2 span.f-12 img {
  filter: brightness(0);
}

.topbar-container img.search-bar-icon {
  filter: brightness(0);
}

.topbar-container span.op-5 {
  opacity: 1;
  filter: brightness(0);
}

// .bell-icon.p-5 {
//   filter: brightness(0);
// }

.header-container {
  background-color: #1e777e;
  padding: 12px;
  border-radius: 5px;
}

.left-item p {
  color: #fff;
}

.overview-menu-item2:hover {
  background-color: #faaa2f;
}

.overview-menu-item {
  background-color: #faaa2f;
}

.overview-menu-item .iconName img {
  filter: brightness(0) !important;
}

.overview-menu-item span svg {
  fill: #000;
}

.col-10.position-relative {
  background-color: #fff;
  height: unset !important;
}

div#root {
  background-color: #fff !important;
}

.main-contents table tbody tr {
  background-color: #e6fafc;
  color: #000;
  border: 1px solid #c5edf1 !important;
}

.main-contents table tbody tr td h6 {
  color: #000 !important;
}

.main-contents table tbody tr td span {
  color: #000;
}

.dark-td-wrap .avtarWrap .profileNameSetting p {
  color: #000;
}

.main-contents table tbody tr td {
  border-top: 1px solid #c5edf1 !important;
  border-bottom: 1px solid #c5edf1 !important;
  // border-right: 1px solid #c5edf1 !important;
}

.workStatusWrap .rework {
  background-color: #000;
}

.dark-td-wrap td:last-child {
  border-right: 1px solid #c5edf1 !important;
}

.main-contents table thead tr th {
  background-color: #e6fafc !important;
  color: #000;
  border-bottom: 1px solid #fff !important;
}

.main-contents table tbody tr td {
  border-bottom: 1px solid #fff !important;
  color: #000 !important;
}

span.nameText {
  color: #000;
}

.main-contents table tbody {
  color: #000;
}

.main-contents table tbody .table-row:hover td {
  background-color: unset !important;
  /* color: #fff !important; */
  border-right: unset !important;
}

/* .main-contents table tbody .table-row:hover .nameText {
  color: #fff !important;
} */

/* .main-contents table tbody .table-row:hover span.nameText {
  color: #fff !important;
} */

/* .main-contents table tbody .table-row:hover span.user-table-like.statushText {
  color: #fff;
} */

span.user-table-like.statushText {
  color: #fff;
  background-color: #faaa2f;
}

span.user-table-like.statushText {
  color: #000;
}

span.nameTextDeterminats {
  color: #000;
}

/* .main-contents table tbody .table-row:hover span.nameTextDeterminats {
  color: #fff;
}
 
.main-contents table tbody .table-row:hover span.totalContentNumber {
  color: #fff;
} */

.main-contents table tbody tr td svg path {
  fill: #000;
  color: #000 !important;
}

span.see-more {
  color: #000 !important;
}

/* .main-contents table tbody .table-row:hover svg path {
  color: #fff !important;
  fill: #fff;
}
 
 
.main-contents table tbody .table-row:hover span.see-more {
  color: #fff !important;
} */

.bg-dark-tag svg {
  color: #fff;
  fill: #fff;
}

.main-contents table .bg-dark-tag svg path {
  color: #fff;
  fill: #fff;
}

.contentSection {
  background-color: #e6fafc;
  color: #000;
  border: 1px solid #e6fafc;
}

.contentSection .contentList {
  color: #000;
  border-bottom: 1px solid #fff;
}

.contentSection .contentList .leftSideContentList h6 {
  color: #000;
}

.contentSection .contentList .leftSideContentList p {
  color: #000;
}

.out-box-new-content-wrap {
  background-color: #e6fafc;
  color: #000;
  border: 1px solid #e6fafc;
}

.head-tile-create .step-title {
  color: #000;
}

.head-tile-create .main-title {
  color: #000;
}

p.stepper__content {
  color: #000;
}

.list-content-out-wrap
  .list-content-li
  .inner-li-wrap
  .content-type-description {
  color: #000;
}

.list-content-out-wrap .list-content-li .inner-li-wrap p {
  color: #000;
}

.main-contents table tbody tr th {
  border-bottom: 1px solid #fff;
  color: #000 !important;
}

.main-contents table tbody tr td label {
  color: #000 !important;
}

/* .main-contents table tbody .table-row:hover span span {
  color: #fff;
} */

/* .user-body .MuiBox-root {
  filter: brightness(0);
} */

/* .AnsBox label {
  color: #000;
} */

.AnsBox label {
  color: #000 !important;
}

.AnsBox input {
  color: #000;
}

.AnsBox .MuiInputBase-root fieldset span {
  color: #000 !important;
}

.user-body button.MuiButtonBase-root {
  filter: brightness(0);
}

.user-body h6.MuiTypography-root.MuiTypography-subtitle1 {
  color: #000;
}

.AnsBox .MuiInputBase-root svg {
  color: #000;
  fill: #000;
}

.AnsBox .MuiInputBase-root svg path {
  fill: #000 !important;
}

.movements-ul li {
  background-color: #e6fafc;
}

.movements-ul li p {
  color: #000;
}

.movements-ul li h3 {
  color: #000;
}

.movements-ul li img {
  filter: brightness(0);
  border: 1px solid #000;
  border-radius: 50%;
}

/* .main-contents table tbody .table-row:hover th {
  color: #fff !important;
} */

/****************/
.main-contents .container-fluid {
  background-color: #e6fafc;
}

.main-contents .container-fluid button {
  color: #000 !important;
}

.main-contents .container-fluid .MuiBox-root label {
  color: #000 !important;
}

.main-contents
  .container-fluid
  .MuiBox-root
  .MuiFormControl-root
  label#\:r2t\:-label {
  background-color: #fff;
}

.main-contents
  .container-fluid
  .MuiBox-root
  .MuiFormControl-root
  label#\:r3b\:-label {
  background-color: #fff;
}

.program-content h1 {
  color: #000;
}

.ql-container.ql-snow p {
  color: #fff;
}

.apexcharts-gridlines-horizontal line {
  stroke: #1a19195c !important;
}

.main-contents .container-fluid span.MuiTabs-indicator {
  background-color: #000;
}

.user-container.user-container-add-space {
  background-color: #1e777e;
}

.user-container svg path {
  stroke: #fff;
  fill: #fff !important;
}

.user-container {
  background-color: #1e777e;
}

.main-contents .container-fluid .MuiFormControl-root.MuiFormControl-fullWidth {
  background-color: #fff;
  border: 1px solid #80808047;
}

.form-text input {
  color: #000;
}

.list-content-out-wrap .list-content-li input {
  color: #000;
}

.select-tag-header {
  color: #000;
}

.step-btn-wrap {
  background-color: #faaa2f;
  color: #000;
}

.step-btn-wrap img {
  filter: brightness(0);
}

/*31-01*/

// img.menu-das-icon {
//   filter: brightness(0);
// }

p.subTitle {
  color: #fff;
}

.user-container svg path {
  stroke: #257076 !important;
}

.table-row:hover {
  background-color: #9eedf5;
}

span.user-table-like.statushText img {
  filter: brightness(0);
}

.main-contents table thead tr th {
  background-color: #257076 !important;
  color: #fff !important;
  // background-color: #9eedf5 !important;
}

.user-body .MuiTablePagination-root {
  background-color: #257076;
  color: #fff;
}

.user-body .MuiTablePagination-root .MuiTablePagination-actions button {
  color: #fff;
  filter: unset;
}

.user-body
  .MuiTablePagination-root
  .MuiInputBase-root.MuiInputBase-colorPrimary
  svg {
  color: #fff;
}

.main-contents .MuiTablePagination-root {
  background-color: #257076;
  color: #fff;
}

.main-contents .MuiTablePagination-root .MuiTablePagination-actions button {
  color: #fff;
  filter: unset;
}

.main-contents
  .MuiTablePagination-root
  .MuiInputBase-root.MuiInputBase-colorPrimary
  svg {
  color: #fff;
}

.cus-in-text-wrap {
  background-color: #fff;
  border: 1px solid #5d5c5c36;
  color: #000;
}

.cus-in-text-wrap:focus-visible {
  border: 1px solid #5d5c5c36 !important;
}

.cus-in-text-wrap:hover {
  background-color: #fff;
  border: 1px solid #5d5c5c36;
}

.main-contents .container-fluid .MuiFormControl-root.MuiFormControl-fullWidth {
  background-color: #fff;
  border: 1px solid #80808047;
}

.form-text input {
  color: #000;
}

//login

div#root {
  background-color: #fff;
}

.login-container {
  background-color: #fff;
}

.login_form {
  background-color: #257076;
}

.MuiFormControl-root.MuiTextField-root {
  background-color: #e8f0fe;
  border-radius: 5px;
}

.login_form h1.title {
  color: #fff;
}

.login_form label span {
  color: #fff;
}

.login_form span.cus-label {
  color: #fff;
}

p.white.bottom-section {
  color: #fff;
}

//

.main-contents .container-fluid {
  background-color: #e7fbfd;
  border-color: #caeaedb0;
}

.main-contents .container-fluid button {
  color: #000 !important;
}

.main-contents .container-fluid .MuiBox-root label {
  color: #000 !important;
}

.main-contents
  .container-fluid
  .MuiBox-root
  .MuiFormControl-root
  label#\:r2t\:-label {
  background-color: #fff;
}

.main-contents
  .container-fluid
  .MuiBox-root
  .MuiFormControl-root
  label#\:r3b\:-label {
  background-color: #fff;
}

.program-content h1 {
  color: #000;
}

.ql-container.ql-snow p {
  color: #000;
}

.apexcharts-gridlines-horizontal line {
  stroke: #1a19195c !important;
}

.main-contents .container-fluid span.MuiTabs-indicator {
  background-color: #000;
}

.user-container.user-container-add-space {
  background-color: #257076;
}

.user-container svg path {
  stroke: #fff;
  fill: #fff !important;
}

.user-container {
  background-color: #257076;
}

.main-contents .container-fluid .MuiFormControl-root.MuiFormControl-fullWidth {
  background-color: #fff;
  border: 1px solid #80808047;
}

.list-content-out-wrap .list-content-li input {
  color: #000;
}

.select-tag-header {
  color: #000;
}

.step-btn-wrap {
  background-color: #faaa2f;
  color: #000;
}

.step-btn-wrap img {
  filter: brightness(0);
}

.create-content-box {
  background-color: #257076 !important;
}

// 03/02/2025
.user-body
  .MuiFormControl-root.ColumnBox
  .MuiOutlinedInput-root.MuiInputBase-root
  .MuiSelect-select.MuiSelect-outlined {
  padding: 0;
  min-height: 35px;
}

.user-body
  .AnsBox
  .MuiOutlinedInput-root.MuiInputBase-root
  .MuiSelect-select.MuiSelect-outlined {
  padding: 12px;
}

.user-body .AnsBox label.MuiInputLabel-root.MuiInputLabel-formControl {
  background-color: #fff;
}

.main-contents
  .MuiFormControl-root.ColumnBox
  .MuiOutlinedInput-root.MuiInputBase-root
  .MuiSelect-select.MuiSelect-outlined {
  padding: 0;
  min-height: 35px;
}

.main-contents
  .AnsBox
  .MuiOutlinedInput-root.MuiInputBase-root
  .MuiSelect-select.MuiSelect-outlined {
  padding: 12px;
}

.main-contents.AnsBox label.MuiInputLabel-root.MuiInputLabel-formControl {
  background-color: #fff;
}
.user-menu-item ul li.active-tabe path {
  fill: #faaa2f !important;
}

.content-bg-green {
  background-color: #e6fafc !important;
}

.main-contents .MuiBox-root ol.MuiBreadcrumbs-ol li.MuiBreadcrumbs-li p {
  line-height: 33px;
  color: #000;
}

.main-contents .MuiBox-root ol.MuiBreadcrumbs-ol li.MuiBreadcrumbs-li a {
  color: #000;
}

.main-contents .MuiBox-root button.MuiButton-root.MuiButton-outlined {
  background-color: #faaa2f;
  color: #000;
}

// Program and subscription rich text editor
.ql-toolbar.ql-snow .ql-stroke {
  stroke: #191818 !important;
}

.ql-toolbar.ql-snow span.ql-picker-label {
  color: #191818 !important;
}
path.ql-fill {
  fill: #191818 !important;
  stroke: #191818 !important;
}

.ql-snow .ql-stroke {
  stroke-width: 1px;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #191818 !important;
  stroke: #19181800 !important;
}

/* Hover effect for toolbar buttons */
.ql-toolbar.ql-snow button:hover .ql-stroke,
.ql-toolbar.ql-snow button:hover .ql-fill {
  stroke: #ff5733 !important;
}

.ql-toolbar.ql-snow span.ql-picker-label:hover {
  color: #ff5733 !important;
}

.ql-toolbar.ql-snow button.ql-active .ql-stroke,
.ql-toolbar.ql-snow button.ql-active .ql-fill {
  stroke: #007bff !important;
}

.ql-toolbar.ql-snow button:hover {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.ql-toolbar.ql-snow button.ql-active {
  background: rgba(0, 123, 255, 0.2);
  border-radius: 4px;
}
.ql-toolbar .ql-formats:nth-of-type(4) svg {
  fill: none !important;
  stroke: #191818 !important;
}

.ql-toolbar .ql-formats:nth-of-type(4) button:nth-of-type(1):hover svg {
  fill: #ff5733 !important;
  stroke: #ff5733 !important;
}

.ql-toolbar .ql-formats:nth-of-type(4) button:nth-of-type(2):hover svg {
  fill: #007bff !important;
  stroke: #007bff !important;
}
