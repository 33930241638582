.width-100 {
  width: 100% !important;
}

.chart-block {
  height: 650px !important;
  width: 35% !important;
  border: 2px solid #283436;
  border-radius: 15px;
  background-size: cover !important;
  background: url(../../../assets/images/result-chart-background.png) no-repeat
    white bottom;
  margin-right: 20px;
  flex-direction: column;
  display: flex;

  div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .MuiIconButton-root:hover {
    background-color: white !important;
  }

  .web-share-buttons {
    gap: 6px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .mobile-share-buttons {
    display: none;
  }

  @media screen and (max-width: 992px) {
    .web-share-buttons {
      display: none;
    }
    .mobile-share-buttons {
      display: block;
      max-width: 250px;
      margin: auto;
    }
  }
}

.chart-detail {
  display: flex;
  justify-content: center;

  &__chart {
    width: 310px;
    top: 310px;
    border: 2px solid #536e72;
    border-radius: 50%;
    border-style: dashed;
    padding: 18px;
  }
}

.chart-title {
  //   margin-top: 80px;
  text-align: center;
  text-transform: uppercase !important;
  display: flex;
  //   flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0px 20px;
  color: white;

  @media screen and (max-width: 540px) {
    // margin-top: 25px;
  }
}

.score-result {
  margin-top: 50px;
  text-align: center;

  @media screen and (max-width: 540px) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.result-body {
  .result-body {
    &__fixed {
      padding: 0px;
      padding-bottom: 30px;
      background-color: #e7fbfd !important;
    }
  }

  .gridContainer {
    max-width: 1400px;
    min-width: auto;
    width: 100%;
  }
}

.result-block {
  //   width: 65% !important;
  height: auto !important;
  border: 2px solid #283436;
  border-radius: 15px;
  background-color: #0e1b1d;
  margin-left: 20px;
}

.ab-close-pop-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.out-card-box-wrap {
  display: flex;
  justify-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 25px 30px;
  padding-bottom: 20px;

  .right-desc-wrap {
    padding-left: 15px;
    padding-right: 25px;

    b {
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.1em;
    }

    p {
      margin: 0px;
      margin-top: 5px;
      font-size: 12px;
      color: #727a7b;
    }
  }
}

.inner-success-modal {
  padding: 0px !important;
  width: 630px !important;
}

.delete-inner-body-pop-up-wrap {
  padding: 10px 10px;
  margin-bottom: 5px;
  overflow-y: auto !important;

  display: grid;
  justify-content: center;

  .ok-button {
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    width: 100px !important;
  }
}

.inner-body-pop-up-wrap {
  padding: 25px 30px;
  margin-bottom: 25px;
  height: 228px !important;
  overflow-y: auto !important;

  @media screen and (max-width: 540px) {
    height: 380px !important;
  }

  p {
    font-size: 14px;
    line-height: 1.6em;
    letter-spacing: 0.021em;
    color: #364143;
  }

  ul {
    list-style: none;
    padding: 0px;
    padding-left: 20px;

    li {
      font-size: 14px;
      line-height: 1.6em;
      letter-spacing: 0.021em;
      margin-bottom: 10px;
      color: #364143;

      span {
        font-family: avenir-bold;
        color: #364143;
      }

      &::before {
        content: "\2022";
        color: #faaa2f;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 786px) {
  .flex-column {
    flex-direction: column;
  }

  .chart-block {
    height: auto !important;
    width: 100% !important;

    margin-right: 0px;
  }

  .result-block {
    width: 100% !important;
    margin-top: 50px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 481px) {
  .ab-top-result-details {
    position: absolute;
    top: 0px !important;
    left: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .delete-button-div {
    margin-right: 20px !important;
    padding-left: 10px;
  }
}

@media screen and (max-width: 540px) {
  .w-95 {
    width: 95% !important;
  }
}

.chart-detail__chart {
  width: 260px !important;
  top: 310px;
  border: 2px solid #536e72;
  border-radius: 50%;
  border-style: dashed;
  padding: 18px;
}

.top-result-details {
  position: relative;

  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 155px;
  }
}

.image-container {
  display: flex;
  align-items: center;
  min-width: 20px;
  max-width: 50px;

  img {
    border-radius: 50px;
    image-rendering: -webkit-optimize-contrast;
    object-fit: cover;
  }
}

.ab-top-result-details {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    padding-left: 20px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: white;
    font-size: 14px;
  }
}

.tooltip-title {
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 900;
}

.inner-bg-result {
  padding: 0px 20px;

  .sub-header {
    p {
      // color: #687072;
      color: #e29c2e;
      font-size: 20px;
      font-weight: 800;
    }
  }

  .MuiTypography-root {
    color: white !important;
    font-size: 15px !important;

    @media screen and (max-width: 540px) {
      font-size: 12px !important;
    }
  }
}

.MuiAccordionSummary-content {
  align-items: center;
  justify-content: space-between;
}

.MuiAccordion-root {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid #2d393a;
  margin-bottom: 15px;
  border-radius: 15px !important;
}

.MuiTypography-root:nth-child(1) {
  font-size: 17px !important;
  color: #000;

  @media screen and (max-width: 540px) {
    font-size: 13px !important;
  }
}

.MuiAccordionSummary-expandIconWrapper {
  color: white !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: auto !important;
  border-bottom: 1px solid #2d393a !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0px !important;
}

.MuiAccordion-root.Mui-expanded:last-of-type {
  margin: 16px 0 !important;
}

.inner-bg-result {
  ul {
    list-style-type: none;
    list-style-position: inside;
    margin: 0px;
    padding: 0px;

    li {
      padding: 0px;
      margin: 0px;
      margin-bottom: 10px;
      font-size: 16px;
      color: #faaa2f;
      text-decoration: underline;

      &:nth-last-child(1) {
        margin-bottom: 0px;
      }

      &:hover {
        a {
          color: #faaa2f;
          text-decoration: underline;
        }
      }

      &::before {
        content: "";

        display: inline-block;

        height: 14px;

        width: 15px;

        background-size: 15px;

        // background-image: url("../../../assets/images/light-list.png");

        background-repeat: no-repeat;

        margin-right: 5px;
      }

      a {
        color: white;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

$light-bg-color: rgba(0, 0, 0, 0.1);
$dark-bg-color: rgba(0, 0, 0, 0.2);
$animation-duration: 1.5s;

.skeleton-highlight {
  background-color: $light-bg-color; // Light background color
  border-radius: 4px; // Optional: Rounded corners
  animation: pulse $animation-duration infinite; // Pulsing effect

  @keyframes pulse {
    0% {
      background-color: $light-bg-color;
    }
    50% {
      background-color: $dark-bg-color;
    }
    100% {
      background-color: $light-bg-color;
    }
  }
}

@keyframes hearbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
