.settingProfileSection {
    background-color: white;
    padding: 15px;

    border-radius: 10px;
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-top: 18px;


    .leftSideProfile {
        width: 50%;
        display: flex;
        align-items: center;

        .imageWrap {
            padding-right: 10px;

            img {
                width: 100%;
            }
        }

        .profileNameSetting {
            p {
                color: #69989f;
            }
        }
    }

    .rightSideProfile {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        
    }
}

.titleHead {
    color: white;
    margin-top: 20px;
    display: inline-block;
    margin-bottom: 15px;
    position: relative;
}

.contentSection {
    position: relative;
    display: inline-block;
    background-color: #0e1b1d;
    border: 1px solid #263234;
    border-radius: 13px;

    width: 100%;

    .contentList {
        border-bottom: 1px solid #263234;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:nth-last-child(1) {
            border-bottom: 0px;
        }

        .leftSideContentList {
            h6 {
                color: white;
                font-family: 'avenir-bold';
                margin-bottom: 4px;
            }

            p {
                color: white;
                margin-bottom: 0px;
                font-size: 14px;
            }
        }
        .rightSideContentList{
            h6 {
                color: #0BD7EE;
                font-family: 'avenir-bold';
                text-transform: uppercase;
                cursor: pointer;
            }

        }

    }
}