.subTitleText {
  text-align: center;
  font-family: "avenir-bold";
  padding-top: 17px;
}

.outerDiv {
  display: flex;
  align-items: center;
}

.dialog-container-lg {
  width: 540px !important;
  padding: 30px;
  padding-bottom: 0%;
}

.btnOutline-non-shadow {
  box-shadow: none !important;
}

.cusDrop {
  fieldset {
    border-color: #1976d2 !important;
    border-width: 0px !important;
  }

  // .MuiSelect-iconOpen+fieldset {
  //     border: 1px solid grey !important;
  // }
}

.out-cust-drop {
  .MuiFormControl-root {
  }
}
