.video-tabe-wrap {
    .workStatusWrap.video-tage-inner {
        p {
            position: relative;
            border-radius: 0px;
            padding: 5px 20px;
            padding-left: 50px;

            span.tri-left {
                position: absolute;
                left: 0px;
                display: block;
                top: 0px;
                width: 0;
                height: 0;
                border-top: 14px solid transparent;
                border-left: 13px solid #0e1b1d;
                border-bottom: 14px solid transparent;
            }

        }

        .approved {

            background-color: #3BB06A !important;
            color: #fff;

        }

        .rework {
            background-color: #FAAA2F;
            color: #000;
        }
        .under-review {
            background-color: #FAAA2F;
            color: #000;
        }
    }
}
.body-part-tag{
    color: #7b8081;
    background-color: transparent !important;
}