@import '../../css/variables.scss';

.topbar-container {
  background-color: $global-background;
  color: white;
  display: flex;
  justify-content: space-between;
 
  padding-right: 40px;
  padding-top: 25px;
  padding-bottom: 10px;
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 100;
  width: 100%;

}

input {
  background-color: $global-background;
  padding: 10px;
  outline: none;
  border: none;
  color: white;
  font-size: 15px;
}
.subtitle-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.right-item {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.msg-text{
  font-family: 'avenir-semi';
}

.gap-between {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.bell-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.menu-item-container {
  background-color: $global-background;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px 0 10px;

}

.right-menu-item {
  display: flex;
  justify-items: center;
  justify-content: space-around;
  align-items: center;
}

.clsBtn {
  padding: 16px 30px !important;
  height: auto;
  background: #257076 !important;
  color: white !important;
}

.search-bar-icon {
  opacity: 0.5;
}

.menu-icon-wrap {
  display: inline-block;
  cursor: pointer;
  border-right: 1px solid #727A7B;
  margin-right: 10px;
  padding-right: 10px;
  height: 20px;

  .menu-das-icon {
    margin-top: -10px;
  }

}

.user-details-pop-up-side {
  text-align: right;

  img {
    cursor: pointer;
  }

  span {
    font-family: 'avenir-bold';
  }

  p {
    color: #757d7e;
  }
}

.chanePasswordLabel {
  color: #0BD7EE;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
}

.sideMsgContainer {
  height: 50px;
  width: 100px;
  margin-right: 15px;
  cursor: pointer;
}

.cus-btn-62 {
  width: 62% !important;
}

.cus-btn-new {
  margin: 0px !important;
}

.ab-dp-noti {
  position: absolute;
  right: 0px;
  top: 50px;
  z-index: 100;
  background-color: white !important;
  border: 2px solid #263234;
  width: 500px;
  border-radius: 10px;

  .clear-bottom-wrap {
    border-top: 2px solid #263234;
    padding: 10px;
    text-align: center;

    h3 {
      color: #0BD7EE;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.2em;
      font-weight: 700;
    }
  }
}


.drop-ul-wrap {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0px;
  margin: 0px;
  height: 320px;
  overflow-y: scroll;

  li {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;


    border-bottom: 2px solid #263234;

    padding: 10px 14px;

    &:nth-last-child(1) {
      border-bottom: 0px;
    }

    .noti-desc-wrap {
      display: flex;
      gap: 5px;
      align-items: start;
    }

    .inner-span-tag-wrap {
      color: #727A7B;
      font-size: 12px;

      p {
        font-size: 15px;
        color: black;
        display: block;

        margin-bottom: 5px !important;
      }

    }
  }

}


.drop-ul-wrap::-webkit-scrollbar {

  width: 0px;
  height: 4px;

}

.drop-ul-wrap ::-webkit-scrollbar-thumb {

  cursor: pointer;
  background: #e5e7e7;
  border-radius: 10px;
}


.ab-dp-profile {
  position: absolute;
  right: 0px;
  top: 50px;
  z-index: 100;
  background-color: #fff;
  border: 2px solid #c5edf1;
  color: #000;
  width: 250px;
  border-radius: 10px;
}
.sort-by-modal-container {
  position: absolute;
  right: 140px;
  top: 170px;
  z-index: 100;
  background-color: #fff;
  border: 2px solid #c5edf1;
  width: 250px;
  border-radius: 10px;
}

.profile-ul-wrap {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0px 20px;
  padding-bottom: 0px;
  margin: 0px;

  li {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;
    padding: 10px 0px;
    padding-bottom: 0px;
    cursor: pointer;
    &:nth-last-child(1){
      padding-bottom: 20px;
    }
    p {
      font-size: 15px;
      color: black;
      display: block;
      &:hover{
        color: #faaa2f;
      }
    }

    p.active-p {
      color: black;
    }
  }

}

.heaer-profile-tag-title {
  padding: 0px 20px;
  padding-top: 20px;

  h5 {
    color: #000;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    // &:hover {
    //   color: #faaa2f;
    // }
  }
}
.lable-fonts{
  font-family: 'avenir-reg';
}
.modal-title {
  font-family: 'avenir-semi';
}
.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-arrow-div{
  background-color: #0BD7EE;
  height: '100px';
  width: '100px';
  position: absolute;
}
.ab-down-wrap-img{
  position: absolute;
  bottom: -5px;
  right: -5px;
  z-index: 100;
}