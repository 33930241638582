.comments-pop-up {

    .inner-comment-section {
        hr {
            margin: 0px;
        }

        .ab-write-comment-section {
            position: absolute;
            left: 0px;
            bottom: 20px;
            z-index: 1111;
            width: 100%;

            .inner-write-comment-section {
                padding: 15px;

                h4 {
                    color: white;
                    text-transform: uppercase;
                    font-size: 15px;
                    letter-spacing: 0.1em;
                }

            }

        }
    }

    .cus-in-wrap {
        background: #172426;
        box-shadow: none;
        outline: none;
        color: white;
        padding: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-color: #222e30;
        float: left;
        resize: none;
    }

    .tag-comments-wrap {
        background: #172426;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-color: #222e30;
        border-width: 1px;
        border-style: solid;
        padding: 10px;
        display: block;
        float: left;
        width: 100%;

        img {
            cursor: pointer;

            &:nth-child(1) {
                margin-right: 10px;
            }
        }
    }

    .MuiDrawer-paperAnchorRight {
        width: 22%;
        background-color: #0e1b1d;
    }

    .comment-head-wrap {
        display: flex;
        padding: 15px;

        h4 {
            color: white;
            font-size: 20px;
            margin: 0px;
            padding: 0px;
        }

        .ab-close-comment-wrap {
            position: absolute;
            right: 15px;
            top: 16px;
            cursor: pointer;
        }
    }
}

.comment-ul {
    padding: 0px;
    margin: 0px;
    position: relative;
    display: inline-block;
    width: 100%;
    float: left;
    height: 438px;
    overflow: auto;

    li {
        padding: 0px;
        margin: 0px;
        position: relative;
        display: inline-block;
        width: 100%;
        float: left;
        padding: 15px;

        .comment-head-tile-wrap {
            display: flex;
            justify-content: space-between;

            .left-side-wrap {
                color: #82898A;
                text-transform: uppercase;
            }

            .right-side-wrap {
                display: flex;

                p {
                    color: #FAAA2F;
                    margin-right: 20px;
                    font-family: 'avenir-semi';
                }

                img {
                    cursor: pointer;
                }
            }
        }
        .h4{
            font-family: 'avenir-semi';
        }

        .desc-wrap {
            padding-top: 10px;

            p {
                color: white;
                font-size: 14px;
            }

        }
    }
}

.comment-ul::-webkit-scrollbar {

    width: 4px;
    height: 4px;

}

.comment-ul::-webkit-scrollbar-thumb {

    cursor: pointer;
    background: white;
    border-radius: 10px;

}

.common-anchor {
    border-radius: 9px;
    background-color: #0bd7ee;
    color: #051315;
    text-transform: uppercase;
    font-size: 15px ;
    font-family: 'avenir-bold';
    text-decoration: none;
    padding: 11px 40px;
    margin-top: 10px;
    display: block;
    float: left;
    text-align: center;
    &:hover{
        color: white;
        background: #858888;
    }
}