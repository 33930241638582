body {
    margin: 0px;
}

#root{
    margin: 0;
}
/* body > #root > div {
    height: 100vh;
  } */
.login-container{
    height: 100%;
    width: 100%;
    background-color: #051315;

    min-height: 610px;
}

.gridContainer {
    width: 65%;
    padding-left: 0.75%;
    padding-right: 0.75%;
    margin: auto;
    clear: none;
    float: none;
    margin-left: auto;
}
.text-center{
    text-align: center;
}
.main-content {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .4);

    display: flex;
}
.out-box-wrap {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.company__info {
    background-color: #008080;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    position: relative;
    color: #fff;
    width: 50%;
}

.fa-android {
    font-size: 3em;
}

@media screen and (max-width: 640px) {

    .main-content {
        width: 90%;
    }

    .left-side-box {
        display: none;
    }

    .right-side-box {
        width: 100% !important;
    }

    .login_form {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
}

@media screen and (min-width: 642px) and (max-width:800px) {
    .main-content {
        width: 70%;
    }
}

.login_form {
    width: auto !important;
    height: auto !important;
    min-height: 100%;
    background-color: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 0px;
    width: 50%;
    background-color: #142123;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 50px;
}

@media screen and (max-width: 540px) {


    .ab-bg-image {
        overflow: hidden;

        img {
            width: 200%;
            max-width: none;
        }
    }
}

@media screen and (max-width: 992px) {

    .out-box-wrap {
        margin: 10px !important;
    }
}
form {
    padding: 0 2em;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.yellow-font {
    color: #E29C2E;
    cursor: pointer;
}
.cus-label{
    color: white;
}
.title{
    color: white;
}
.subTitle{
    color: #727A7B;
}
.text-right{
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-end;
}
.white{
    color: #fff;
}
.ab-inner-image-wrap{
    position: absolute;
    left: 0px;
    top: 110px;
    width: 100%;
    height: 100%;
   
}
img{
    max-width: 100%;
}