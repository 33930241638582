@import "../../css/variables.scss";

.mobile-panel-icon {
  display: none;
}
@media screen and (max-width: 480px) {
  .mobile-panel-icon {
    display: block;
    position: fixed;
    left: 200px;
    top: 20px;

    z-index: 99999;
  }
}

.sidebar-container {
  background-color: $global-background;

  height: 100vh;
  color: white;
  transition: all 0.3s ease-out;
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 999;
}
@media screen and (max-width: 480px) {
  .sidebar-container {
    transform: translateX(-100%);
    position: absolute;
    left: 0;
    z-index: 9999;
  }
}
.sidebar-container.show {
  transform: translateX(0%);
}
.sidebar-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  padding-right: 0px;
}
.side-image-container {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
}
.side-image-container span {
  font-size: 12px;
  margin: 5px 0px 5px 0px;
  color: gray;
}
.side-image-container h1 {
  font-size: 20px;
}
.left-inner-image {
  width: 100%;
  height: auto;
  padding: 5px;
}
.overview-text {
  font-size: 9px;
  color: rgb(159, 151, 151);
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 10px;
  letter-spacing: 0.3em;
}
.general-text {
  font-size: 9px;
  color: rgb(159, 151, 151);
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 10px;
  letter-spacing: 0.3em;
}

.overview-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -10px;
}
.overview-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  padding: 5px;
  border-radius: 10px;
  background-color: #0f1c1e;
  font-family: "avenir-bold";
  color: #fff;
  cursor: pointer;
}
.overview-menu-item2 {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}
.overview-menu-item2 > img {
  margin-right: 10px;
}
.overview-menu-item2:hover {
  background-color: #0f1c1e;
}
.overview-menu-item > img {
  margin-right: 10px;
}
.iconName {
  display: flex;
  align-items: center;
}

.general-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -10px;
}

.general-menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.general-menu-item > img {
  margin-right: 10px;
}

.sidebar-container.false {
  overflow: auto;
}

ul.overview-menu li span {
  font-size: 14px;
  margin-left: 7px !important;
}

ul.general-menu  li span {
  font-size: 14px;
  margin-left: 7px !important;
}

.sidebar-wrapper{
  padding-left: 5px;
}

.overview-menu li .iconName img {
  max-width: 18px;
}

ul.general-menu li .iconName img {
  max-width: 18px;
}