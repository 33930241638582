.categiries-row {
    th {
        border-top-left-radius: 10px;
    }

    td {

        &:nth-last-child(1) {
            border-top-right-radius: 10px;
        }
    }
}
.sub-categiries-row{
    tr{
        &:nth-last-child(1) {
            td{
                &:first-child {
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }
}
