.progrss-body {
  // position: absolute;
  width: 100%;
  // z-index: 10;
  top: 140px;
  //   height: 69.9vh;
  overflow: auto;

  &__fixed {
    display: flex;
    display: block;
    background-color: #e7fbfd;
    padding: 5px 129px 5px;
    margin-bottom: 10px;

    @media screen and (max-width: 992px) {
      padding: 5px 17px 5px;
    }
  }

  &__header {
    padding: 5px 129px 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 24%;
    overflow: auto;
    z-index: 11;
    height: 80%;

    @media screen and (max-width: 992px) {
      padding: 5px 50px 5px;
    }
  }

  @media screen and (max-width: 992px) {
    top: 165px;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #6b7374;
  border-radius: 10px;
}

.progress-footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #041214;
  z-index: 100;

  &__fix-footer {
    background-color: #051315;
    position: relative;
    padding: 14px 0px;
    display: flex;
    justify-content: space-between;
  }

  .btn {
    height: 48px !important;
  }

  &__button {
    &--deactive {
      background-color: #727a7b !important;
      color: #fff !important;
      font-size: 15px !important;
      margin: 0px !important;
    }

    &--active {
      background-color: #0bd7ee !important;
      color: #000 !important;
      font-size: 15px !important;
      margin: 0px !important;
    }
  }

  .gridContainer {
    max-width: 1400px;
    min-width: auto;
  }
}

.questions-list {
  &__question {
    background: #0e1b1d;
    border: 2px solid #1d2a2b;
    border-radius: 15px;
    margin-top: 10px;

    &--main {
      display: flex;
      padding: 20px;

      &--count {
        margin-left: 20px;
        font-size: 20px !important;

        label {
          color: white;
        }

        @media screen and (max-width: 540px) {
          margin-left: 0px !important;
        }
      }

      &--question {
        margin-left: 20px;
        width: 100%;

        label {
          color: white;
          margin-bottom: 10px;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }
        @media screen and (max-width: 540px) {
          margin-left: 5px;
        }
      }

      @media screen and (max-width: 992px) {
        padding: 10px;
      }
    }

    &__active {
      border: 2px solid #86640e;
    }
  }

  .break-text {
    white-space: break-spaces !important;
  }
}

.question-title {
  font-size: 20px !important;
}

.question-option {
  font-size: 16px !important;
}

.cus-radio-parent {
  .MuiButtonBase-root {
    padding-bottom: unset !important;
    padding-top: 4px !important;
  }

  .MuiFormControlLabel-root {
    margin-bottom: 10px;
    -webkit-align-items: self-start;
    -webkit-box-align: start;
    align-items: self-start;
  }
}

.cus-res-wrap {
  display: inline-block;
  width: auto !important;
  float: right;
}

.cus-checkbox-parent {
  .MuiButtonBase-root {
    padding-bottom: unset !important;
    padding-top: 2px !important;
  }

  .MuiFormControlLabel-root {
    margin-bottom: 10px;
    -webkit-align-items: self-start;
    -webkit-box-align: start;
    align-items: self-start;
  }
}

@media screen and (max-width: 786px) {
  .cus-checkbox-parent {
    .modal-button {
      height: 44px !important;
    }
  }
}

.textbox-size {
  height: auto !important;
  width: 100%;
  color: #fff !important;
}

.margin-0 {
  margin: 0px !important;
  margin-top: 6px !important;
}

.qus-text-box {
  display: flex !important;
  align-items: flex-end !important;

  .MuiFormControl-root.MuiTextField-root {
    margin-bottom: 0px !important;
  }
}

.ok-button {
  &--deactive {
    background-color: #727a7b !important;
    color: #fff !important;
    font-size: 15px !important;
    margin: 0px !important;
  }

  &--active {
    background-color: #0bd7ee !important;
    color: #000 !important;
    font-size: 15px !important;
    margin: 0px !important;
  }
}

.yellow-font {
  color: #E29C2E !important;
}
