.ContentWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.3% 2.5%;
}



.ContentCardList .content-head-sub-title {
    font-family: 'avenir-semi';
    font-size: 17px;
    color: white;
}

.ContentCardList .by-author {
    font-family: 'avenir-semi';
    color: #7d8384;

}

.card-image {
    position: relative;
}

.ab-star-wrap {
    position: absolute;
    right: 20px;
    top: 10px;
    background: white;
    border-radius: 10px;
    padding: 4px 10px;
    cursor: pointer;
    z-index: 10;

    svg {
        width: 20px;
        margin-right: 5px;

    }

    p {
        font-size: 12px;
        margin: 0px;
        display: inline-block;
        font-family: 'avenir-bold';
    }

    [data-testid="StarRateIcon"] {
        stroke: black;
    }
}

.ab-shade {
    position: absolute;
    width: 100%;
    bottom: 0px;
    z-index: 0;

    img {
        width: 100%;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.tag-name {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 11;

    p {
        background-color: #40CE79;
        padding: 2px 30px;
        padding-bottom: 0px;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 0px;
        letter-spacing: 0.1em;
        color: white;
        border-radius: 5px;
        line-height: 1.6em;
    }
}

.eye-count {

    color: white;
}

@media screen and (max-width:1200px) {
    .ContentWrapper {

        grid-template-columns: repeat(3, 1fr);

    }
}

@media screen and (max-width:992px) {
    .ContentWrapper {

        grid-template-columns: repeat(2, 1fr);

    }
}

.dark-td-wrap {
    cursor: pointer;
    th {
        border-bottom-left-radius: 10px !important;
        border-top-left-radius: 10px !important;

        border-left: 1px solid #263133 !important;
        border-top: 1px solid #263133 !important;
        border-bottom: 1px solid #263133 !important;

        img {
            border-bottom-left-radius: 10px !important;
            border-top-left-radius: 10px !important;
            width: 100%;
            height: 97px;
        }
    }

    td {
        border-top: 1px solid #263133 !important;
        border-bottom: 1px solid #263133 !important;

        &:nth-last-child(1) {
            border-bottom-right-radius: 10px !important;
            border-top-right-radius: 10px !important;
            border-right: 1px solid #263133 !important;
        }
    }

    .tile-table-description {
        color: white;

        span {
            color: #727a7b;
            font-size: 12px;
            text-transform: uppercase;
        }

        h6 {
            white-space: nowrap;
            width: 269px;
            overflow: hidden;
        }

    }
    .title-second-line{
        text-overflow: ellipsis;
    }

    .tile-line-one {
        white-space: nowrap;
        width: 269px;
        overflow: hidden;
    }

    .avtarWrap {
        display: flex;
        align-items: center;
        color: white;

        .imageWrap {
            img {
                width: 50px;
            }
        }

        .profileNameSetting {
            margin-left: 10px;

            p {
                font-size: 14px;
                color: #4d5758;
            }
        }
    }

    .table-rate-view-wrap {
        display: flex;
    }
}

.bg-dark-tag {
    background: #1b2829;
    margin: 10px;
    display: flex;
    padding: 3px 12px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #263133;
}

.workStatusWrap {
    p {
        display: inline-block;
        padding: 4px 20px;
        border-radius: 5px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    .approved {

        background-color: #193f30;
        color: #40ce79;

    }

    .rework {
        background-color: #3e3821;
        color: #faaa2f;
    }

    .disapproved {
        background-color: #362023;
        color: #D33336;
        opacity: 0.5;
        user-select: none;
    }
}

.listLoader {
    font-family: 'avenir-bold';
    font-size: 35px;
}