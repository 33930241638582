.movements-ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.2%;
  padding: 0px;
  margin-top: 20px;

  li {
    padding: 0px;
    list-style: none;
    background-color: #0f1c1e;
    border: 1px solid #1d292b;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 14px 10px;
    p{
      color: #727A7B;
      margin-top: 10px;
      font-size: 17px;
    }
    h3{
      margin-top: 10px;
      color: white;
      font-size: 26px;
      font-family: 'avenir-bold';
    }
  }
}