.quill-container {
  max-width: 100%;
  // min-height: 200px; /* Adjust as necessary */
  overflow: auto;
}

.ql-editor {
  text-align: left; /* Default to left aligned */
}

.ql-editor img {
  max-width: 100%;
  display: inline-block; /* Ensures the image is part of the flow */
  margin: 10px 0;
}

.ql-editor img.align-left {
  float: left;
  margin-right: 10px;
}

.ql-editor img.align-right {
  float: right;
  margin-left: 10px;
}

.ql-editor img.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-text {
  padding: 0px;
  /* border-color: #1d2b2c; */
  border: unset;
}

.form-text input {
  padding: 5px 15px;
  color: #fff;
  border-color: #000;
}

.form-text input:focus {
  border-color: #000;
  outline: unset;
  outline-offset: unset;
}

/* For Webkit browsers like Chrome, Safari */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Poppins&family=Open+Sans&family=Montserrat&family=Oswald&family=Jaro&family=Parkinsans&family=Arial&family=Georgia&family=Times+New+Roman&family=Courier+New&family=Ubuntu&family=Raleway&family=Quicksand&family=Playfair+Display&family=Lora&family=Fira+Sans&family=Roboto+Mono&family=Lobster&family=Permanent+Marker&display=swap");

/* Display font family from data attributes */
.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

/* Apply the fonts to Quill editor */
.ql-font-Roboto {
  font-family: "Roboto", sans-serif !important;
}

.ql-font-Calibri {
  font-family: Calibri, Arial, sans-serif !important;
}

.ql-font-Poppins {
  font-family: "Poppins", sans-serif !important;
}

.ql-font-Open_Sans {
  font-family: "Open Sans", sans-serif !important;
}

.ql-font-Montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.ql-font-Oswald {
  font-family: "Oswald", sans-serif !important;
}

.ql-font-Jaro {
  font-family: "Jaro", sans-serif !important;
}

.ql-font-Parkinsans {
  font-family: "Parkinsans", sans-serif !important;
}

.ql-font-Arial {
  font-family: "Arial", sans-serif !important;
}

.ql-font-Georgia {
  font-family: "Georgia", serif !important;
}

.ql-font-Times_New_Roman {
  font-family: "Times New Roman", serif !important;
}

.ql-font-Courier_New {
  font-family: "Courier New", monospace !important;
}

.ql-font-Ubuntu {
  font-family: "Ubuntu", sans-serif !important;
}

.ql-font-Raleway {
  font-family: "Raleway", sans-serif !important;
}

.ql-font-Quicksand {
  font-family: "Quicksand", sans-serif !important;
}

.ql-font-Playfair_Display {
  font-family: "Playfair Display", serif !important;
}

.ql-font-Lora {
  font-family: "Lora", serif !important;
}

.ql-font-Fira_Sans {
  font-family: "Fira Sans", sans-serif !important;
}

.ql-font-Roboto_Mono {
  font-family: "Roboto Mono", monospace !important;
}

.ql-font-Lobster {
  font-family: "Lobster", cursive !important;
}

.ql-font-Permanent_Marker {
  font-family: "Permanent Marker", cursive !important;
}

/* Default font for Quill editor */
.ql-editor {
  font-family: "Roboto", sans-serif !important;
  min-height: 200px;
}

/* Font family dropdown label css  */
.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", cursive;
  content: "Roboto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Calibri"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Calibri"]::before {
  font-family: "Calibri", cursive;
  content: "Calibri" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Poppins"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Poppins"]::before {
  font-family: "Poppins", cursive;
  content: "Poppins" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Open_Sans"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Open_Sans"]::before {
  font-family: "Open_Sans", cursive;
  content: "Open_Sans" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", cursive;
  content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Oswald"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Oswald"]::before {
  font-family: "Oswald", cursive;
  content: "Oswald" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Jaro"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Jaro"]::before {
  font-family: "Jaro", cursive;
  content: "Jaro" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Parkinsans"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Parkinsans"]::before {
  font-family: "Parkinsans", cursive;
  content: "Parkinsans" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
  font-family: "Arial", cursive;
  content: "Arial" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
  font-family: "Arial", cursive;
  content: "Arial" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Georgia"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Georgia"]::before {
  font-family: "Georgia", cursive;
  content: "Georgia" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Times_New_Roman"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Times_New_Roman"]::before {
  font-family: "Times_New_Roman", cursive;
  content: "Times_New_Roman" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Courier_New"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Courier_New"]::before {
  font-family: "Courier_New", cursive;
  content: "Courier_New" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Ubuntu"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Ubuntu"]::before {
  font-family: "Ubuntu", cursive;
  content: "Ubuntu" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
  font-family: "Raleway", cursive;
  content: "Raleway" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Quicksand"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Quicksand"]::before {
  font-family: "Quicksand", cursive;
  content: "Quicksand" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Playfair_Display"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Playfair_Display"]::before {
  font-family: "Playfair_Display", cursive;
  content: "Playfair_Display" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Lora"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lora"]::before {
  font-family: "Lora", cursive;
  content: "Lora" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Fira_Sans"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Fira_Sans"]::before {
  font-family: "Fira_Sans", cursive;
  content: "Fira_Sans" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Roboto_Mono"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto_Mono"]::before {
  font-family: "Roboto_Mono", cursive;
  content: "Roboto_Mono" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Lobster"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lobster"]::before {
  font-family: "Lobster", cursive;
  content: "Lobster" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Permanent_Marker"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Permanent_Marker"]::before {
  font-family: "Permanent_Marker", cursive;
  content: "Permanent_Marker" !important;
}

/* Increase font family dropdown width  */
.ql-font.ql-picker {
  width: 150px !important;
}

.ql-picker-options {
  max-height: 400px;
  overflow-y: auto;
}

// Image toolbar css
.toolbar div:first-child {
  display: none !important;
}
.toolbar {
  position: absolute !important;
  top: -33px !important;
  width: fit-content !important;
  display: flex;
  align-items: center;
  height: 30px !important;
  border: 1px solid white;

  .input-wrapper input {
    background: white !important;
  }
  .group {
    margin-top: 0px !important;
    display: flex;
    align-items: center;
    border: none !important;
    .btn {
      padding: 0px 5px !important;
      margin: 2px !important;
      height: 22px;
      border-radius: 2px !important;
      background-color: "#727A7B" !important;
    }
  }
}
