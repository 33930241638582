.slide-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #263133;
  padding: 4px;
}

.slide-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rating-1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 3px;
}
.rating-2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.slide-card h6 {
  margin: 5px 0px;
}
.slide-card p {
  margin: 4px;
}
.profession{
    font-size: 12px;
    color: gray;
}
.rating-text{
  font-size: 13px;
  padding-top: 3px;
}
